import(/* webpackMode: "eager" */ "/app/apps/lanna/src/ui/components/Carousel/Carousel.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lanna/src/ui/components/cart/cart.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lanna/src/ui/components/Heading/Heading.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lanna/src/ui/components/Link/Link.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lanna/src/ui/components/MainNavigation/components/HeaderWrapper.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lanna/src/ui/components/MainNavigation/MainNavigation.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lanna/src/ui/components/navigation/page/PageHeaderLogo.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lanna/src/ui/components/Page404/Page404.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lanna/src/ui/components/ProductCard/ProductCardBadges.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lanna/src/ui/components/ProductCard/ProductCardRoot.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lanna/src/ui/components/search/search.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lanna/src/ui/components/Stock/Stock.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lanna/src/ui/components/Topbar/components/CustomerServicePopup.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lanna/src/utils/imageLoader.ts");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.1.0_@babel+core@7.26.0_@opentelemetry+api@1.6.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.1.0_@babel+core@7.26.0_@opentelemetry+api@1.6.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js")